<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <blueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>
  </section>
</template>
 
  <!-- #################################################################################### -->
  <!-- ###### Sección de Scripts                                                     ###### -->
  <!-- #################################################################################### -->
<script>
import { Role } from "@/router/role.js";
import BlueHeader from '../../../../components/BlueHeader.vue';

export default {
  name: 'Tracking',
  components: { BlueHeader },
  data: () => ({
    itemsHeader: [
      {
        name: 'Rastreo',
        link: { name: 'modules.logistics.tracking.rastreo' },
        rol: Role.Logistica_Tracking_Rastreo,
      },
      {
        name: 'Auditoria de transporte',
        link: { name: 'modules.logistics.tracking.auditoriaTransporte' },
        rol: Role.Logistica_Tracking_Rastreo,
      },
    ]
  }),

}
</script>
 
  <!-- #################################################################################### -->
  <!-- ###### Sección de Styles                                                      ###### -->
  <!-- #################################################################################### -->
<style scoped>
.subHeader-content {
  width: 100%;
}
</style>